export const user = (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="fi-rr-user" clipPath="url(#clip0_456_1477)">
      <path
        id="Vector"
        d="M10.5 9.99994C11.4889 9.99994 12.4556 9.7067 13.2779 9.15729C14.1001 8.60788 14.741 7.82699 15.1194 6.91336C15.4978 5.99973 15.5969 4.99439 15.4039 4.02449C15.211 3.05458 14.7348 2.16367 14.0355 1.46441C13.3363 0.765145 12.4454 0.288941 11.4755 0.0960148C10.5055 -0.0969113 9.50021 0.00210539 8.58658 0.380544C7.67295 0.758982 6.89206 1.39984 6.34265 2.22209C5.79324 3.04434 5.5 4.01103 5.5 4.99994C5.50132 6.32562 6.02853 7.59662 6.96593 8.53401C7.90332 9.47141 9.17432 9.99862 10.5 9.99994ZM10.5 1.66661C11.1593 1.66661 11.8037 1.8621 12.3519 2.22838C12.9001 2.59465 13.3273 3.11524 13.5796 3.72433C13.8319 4.33342 13.8979 5.00364 13.7693 5.65024C13.6407 6.29685 13.3232 6.89079 12.857 7.35696C12.3908 7.82314 11.7969 8.14061 11.1503 8.26923C10.5037 8.39784 9.83348 8.33183 9.22439 8.07954C8.6153 7.82725 8.09471 7.40001 7.72844 6.85184C7.36216 6.30368 7.16667 5.65921 7.16667 4.99994C7.16667 4.11589 7.51786 3.26804 8.14298 2.64292C8.7681 2.0178 9.61595 1.66661 10.5 1.66661Z"
        fill="#667085"
      />
      <path
        id="Vector_2"
        d="M10.5 11.6672C8.51155 11.6694 6.60518 12.4603 5.19914 13.8663C3.79309 15.2724 3.00221 17.1787 3 19.1672C3 19.3882 3.0878 19.6002 3.24408 19.7564C3.40036 19.9127 3.61232 20.0005 3.83333 20.0005C4.05435 20.0005 4.26631 19.9127 4.42259 19.7564C4.57887 19.6002 4.66667 19.3882 4.66667 19.1672C4.66667 17.6201 5.28125 16.1363 6.37521 15.0424C7.46917 13.9484 8.9529 13.3338 10.5 13.3338C12.0471 13.3338 13.5308 13.9484 14.6248 15.0424C15.7188 16.1363 16.3333 17.6201 16.3333 19.1672C16.3333 19.3882 16.4211 19.6002 16.5774 19.7564C16.7337 19.9127 16.9457 20.0005 17.1667 20.0005C17.3877 20.0005 17.5996 19.9127 17.7559 19.7564C17.9122 19.6002 18 19.3882 18 19.1672C17.9978 17.1787 17.2069 15.2724 15.8009 13.8663C14.3948 12.4603 12.4884 11.6694 10.5 11.6672Z"
        fill="#667085"
      />
    </g>
    <defs>
      <clipPath id="clip0_456_1477">
        <rect width="20" height="20" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export const user_active = (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5 10C13.2614 10 15.5 7.76142 15.5 5C15.5 2.23858 13.2614 0 10.5 0C7.73858 0 5.5 2.23858 5.5 5C5.5 7.76142 7.73858 10 10.5 10Z"
      fill="url(#paint0_linear_854_378)"
    />
    <path
      d="M10.5 11.666C6.35977 11.6706 3.00461 15.0258 3 19.166C3 19.6262 3.37309 19.9993 3.83332 19.9993H17.1666C17.6269 19.9993 18 19.6262 18 19.166C17.9954 15.0258 14.6402 11.6706 10.5 11.666Z"
      fill="url(#paint1_linear_854_378)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_854_378"
        x1="10.5"
        y1="1.61794e-07"
        x2="-2.47793"
        y2="20.311"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#384BCA" />
        <stop offset="1" stop-color="#7B8CFF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_854_378"
        x1="10.5"
        y1="11.666"
        x2="2.98556"
        y2="32.8347"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#384BCA" />
        <stop offset="1" stop-color="#7B8CFF" />
      </linearGradient>
    </defs>
  </svg>
);
