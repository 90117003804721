export const active_home = (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="#667085"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="fi-sr-home" clipPath="url(#clip0_456_1461)">
      <g id="Group">
        <path
          id="Vector"
          d="M10.5 12.4932C9.1193 12.4932 8 13.6125 8 14.9932V19.9932H13V14.9932C13 13.6125 11.8807 12.4932 10.5 12.4932Z"
          fill="#667085"
        />
        <g id="Group_2">
          <path
            id="Vector_2"
            d="M14.6667 14.9935V19.9935H18C19.3807 19.9935 20.5 18.8742 20.5 17.4935V9.89271C20.5002 9.45978 20.332 9.04376 20.0308 8.73271L12.9492 1.07685C11.6996 -0.275105 9.59074 -0.358112 8.23879 0.891419C8.17457 0.950794 8.1127 1.01263 8.05336 1.07685L0.98418 8.73021C0.673945 9.04255 0.499883 9.46497 0.5 9.90521V17.4935C0.5 18.8742 1.6193 19.9935 3 19.9935H6.33332V14.9935C6.34891 12.7212 8.18355 10.8656 10.3987 10.8121C12.6879 10.7569 14.6492 12.6441 14.6667 14.9935Z"
            fill="#667085"
          />
          <path
            id="Vector_3"
            d="M10.5 12.4932C9.1193 12.4932 8 13.6125 8 14.9932V19.9932H13V14.9932C13 13.6125 11.8807 12.4932 10.5 12.4932Z"
            fill="#667085"
          />
        </g>
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_456_1461"
        x1="10.5"
        y1="12.4932"
        x2="-0.216614"
        y2="23.6745"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#384BCA" />
        <stop offset="1" stopColor="#7B8CFF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_456_1461"
        x1="10.5"
        y1="0.00598177"
        x2="-15.4329"
        y2="40.6174"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#384BCA" />
        <stop offset="1" stopColor="#7B8CFF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_456_1461"
        x1="10.5"
        y1="12.4932"
        x2="-0.216614"
        y2="23.6745"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#384BCA" />
        <stop offset="1" stopColor="#7B8CFF" />
      </linearGradient>
      <clipPath id="clip0_456_1461">
        <rect width="20" height="20" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export const home = (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.8" clipPath="url(#clip0_637_2190)">
      <path
        d="M19.7677 7.55751L13.4468 1.23584C12.6645 0.455835 11.6049 0.0178223 10.5002 0.0178223C9.39546 0.0178223 8.33581 0.455835 7.55351 1.23584L1.23268 7.55751C0.999699 7.789 0.814986 8.06443 0.689247 8.36783C0.563507 8.67124 0.499243 8.99658 0.500178 9.32501V17.5058C0.500178 18.1689 0.76357 18.8048 1.23241 19.2736C1.70125 19.7425 2.33714 20.0058 3.00018 20.0058H18.0002C18.6632 20.0058 19.2991 19.7425 19.7679 19.2736C20.2368 18.8048 20.5002 18.1689 20.5002 17.5058V9.32501C20.5011 8.99658 20.4368 8.67124 20.3111 8.36783C20.1854 8.06443 20.0007 7.789 19.7677 7.55751ZM13.0002 18.3392H8.00018V15.0608C8.00018 14.3978 8.26357 13.7619 8.73241 13.2931C9.20125 12.8242 9.83714 12.5608 10.5002 12.5608C11.1632 12.5608 11.7991 12.8242 12.2679 13.2931C12.7368 13.7619 13.0002 14.3978 13.0002 15.0608V18.3392ZM18.8335 17.5058C18.8335 17.7269 18.7457 17.9388 18.5894 18.0951C18.4332 18.2514 18.2212 18.3392 18.0002 18.3392H14.6668V15.0608C14.6668 13.9558 14.2279 12.896 13.4465 12.1146C12.6651 11.3332 11.6052 10.8942 10.5002 10.8942C9.39511 10.8942 8.3353 11.3332 7.5539 12.1146C6.7725 12.896 6.33351 13.9558 6.33351 15.0608V18.3392H3.00018C2.77916 18.3392 2.5672 18.2514 2.41092 18.0951C2.25464 17.9388 2.16684 17.7269 2.16684 17.5058V9.32501C2.16762 9.10416 2.25534 8.8925 2.41101 8.73584L8.73185 2.41668C9.2016 1.94912 9.8374 1.68663 10.5002 1.68663C11.163 1.68663 11.7988 1.94912 12.2685 2.41668L18.5893 8.73834C18.7444 8.89438 18.8321 9.10503 18.8335 9.32501V17.5058Z"
        fill="#667085"
      />
    </g>
    <defs>
      <clipPath id="clip0_637_2190">
        <rect width="20" height="20" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);
