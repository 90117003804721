export const api_routes = {
  promotion_list: "/api/Customer/GetPromotionListByBrandId",
  promotion_detail: "/api/Customer/GetPromotionDetailById?PromoId=",
  service_list: "/api/Customer/GetProductListByCustomer",
  noti_list: "/api/Customer/GetNotificationListByCustomer",
  service_detail: '/api/Customer/GetProductListByCategoryAndSubCategory',
  blog_list: '/api/Customer/GetBlogListByBrandId',
  blog_detail: '/api/Customer/GetBlogDetailByBlogId',
  blog_react: '/api/Customer/GetBlogReactByCustomer',
  saved_blogs: '/api/Customer/GetBlogSavedList',
  save_blog: '/api/Customer/CreateBlogSavedByCustomer',
  register_user: '/api/Customer/CreateMember',
  forget_password: '/api/Customer/ForgetPassword',
  get_member_info: "/api/Customer/GetMemberInfo",
  update_member_info: "/api/Customer/UpdateCustomerProfile",
  change_number: "/api/Customer/ChangePhoneNo",
  change_password: "/api/Customer/ChangePassword",
  upload_photo: '/api/Customer/UploadProfilePicture',
  faq_type_list: '/api/Customer/GetFAQTypeList',
  faq_list_byType: '/api/Customer/GetFAQListByType',
  reward_list: "/api/Customer/GetRedeemListByBrandId",
  redeem_pending: "/api/Customer/CustomerRedeemItem",
  coupon_list: "/api/Customer/GetRedeemListByCustomer",
  transaction_history: "/api/Customer/GetPointHistory",
  member_tire_level: "/api/Customer/GetTierBenefitList",
  log_out: "/api/Authentication/Logout",
  send_otp: "/api/Authentication/SendOTP",
  membership_level: "/api/Customer/GetMembership",
};
